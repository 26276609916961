body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

@font-face {
  font-family: "Cera Pro Black";
  src: url("/assets/fonts/Cera Pro Black.otf") format("opentype");
}

@font-face {
  font-family: "Cera Pro Bold";
  src: url("/assets/fonts/Cera Pro Bold.otf") format("opentype");
}

@font-face {
  font-family: "Cera Pro Medium";
  src: url("/assets/fonts/Cera Pro Medium.otf") format("opentype");
}

.h1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.167;
  font-family: "Cera Pro Black";
}

.h2 {
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1.2;
  font-family: "Cera Pro Black";
}

.h3 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.167;
  font-family: "Cera Pro Black";
}

.h4 {
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 1.235;
  font-family: "Cera Pro Black";
}

.h5 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.334;
  font-family: "Cera Pro Black";
}

.h6 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  font-family: "Cera Pro Black";
}

#home-page {
  background-image: url(/assets/Home/home-bg.png);
  background-size: contain;
  background-repeat: no-repeat, no-repeat;
  background-position: right 20%;
}

#about-page-section-1 {
  background: url(/assets/about/about-bg1.png);
  background-size: contain;
  background-repeat: no-repeat, no-repeat;
  background-position: right 20%;
}

#about-page-section-2 {
  background: url(/assets/about/about-bg2.png);
  background-size: contain;
  background-repeat: no-repeat, no-repeat;
  background-position: left 30%;
}

#companies {
  background: url(/assets/companies-bg.png);
  background-position: 100%;
  background-size: cover;
  background-repeat: no-repeat, no-repeat;
}
.about-img {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 16px 16px -8px;
  width: 100%;
  height: auto;
}

.fields {
  padding-right: 1rem;
  border-radius: 50px;
}

input::-webkit-input-placeholder {
  font-weight: bold;
}
input:-moz-placeholder {
  font-weight: bold;
}
input:-ms-input-placeholder {
  font-weight: bold;
}

#tutorial-page {
  background-image: url(/assets/employer/employer-bg-1.png),
    url("/assets/pageheader.svg#tutorial");
  background-size: 100% 100%;
  background-repeat: no-repeat, no-repeat;
}

.footer-bg-2 {
  background-color: #f3f8fe;
  background-size: 100% 100%;
  background-repeat: no-repeat, no-repeat;
}

.header-stacktrek-logo {
  width: 200px;
  height: "100%";
}

.footer-stacktrek-logo {
  width: 200px;
  height: auto;
}

.header-top {
  font-size: clamp(45px, (45px + 2vw), 48px);
  font-weight: 800;
  letter-spacing: 0.15rem;
  line-height: 3.25rem;
  color: #ffffff;
}

.header-top-2 {
  font-size: clamp(30px, (30px + 2vw), 33px);
  font-weight: 800;
  letter-spacing: 0.15rem;
  line-height: 2.5rem;
  color: #ffffff;
}

.header-top-content {
  font-size: clamp(15px, (15px + 2vw), 18px);
  color: #ffffff;
}

.body-top {
  font-size: clamp(30px, (30px + 2vw), 33px);
  font-weight: 800;
  letter-spacing: 0.15rem;
  line-height: 2rem;
}

.home-banner {
  font-size: clamp(36px, (36px + 2vw), 38px);
  font-weight: 700;
  line-height: 2.5rem;
}

.mobile-home-banner {
  font-size: clamp(20px, (20px + 2vw), 23px);
  font-weight: 700;
  line-height: 2rem;
}

.home-page-card {
  background: white;
  padding: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 16px 16px -8px;
  border-radius: 8px;
  border: none;
  margin-bottom: 2rem;
}

.card-button {
  font-size: clamp(10px, (10px + 2vw), 13px);
  font-weight: 800;
  color: #ffffff;
  background-color: #ff7a0d;
  border-radius: 50px;
  border: solid #ff7a0d;
  letter-spacing: 0.05rem;
}

.card-title {
  font-size: clamp(14px, (14px + 2vw), 16px);
  font-weight: 700;
  letter-spacing: 0.05rem;
}

.card-content {
  font-size: clamp(10px, (10px + 2vw), 12px);
  min-height: 7rem;
}

.home-nav-link {
  font-size: clamp(10px, (10px + 2vw), 13px);
  font-weight: 800;
  letter-spacing: 0.05rem;
  color: #007bff;
}

.body-top-2 {
  font-size: clamp(25px, (25px + 2vw), 28px);
  font-weight: 800;
  letter-spacing: 0.15rem;
  line-height: 2rem;
}

.body-top-3 {
  font-size: clamp(20px, (20px + 2vw), 23px);
  font-weight: 800;
  letter-spacing: 0.15rem;
  line-height: 1.75rem;
}

.body-top-4 {
  font-size: clamp(18px, (18px + 2vw), 21px);
  font-weight: 800;
  letter-spacing: 0.05rem;
  line-height: 2rem;
}

.body-top-5 {
  font-size: clamp(15px, (15px + 2vw), 18px);
  font-weight: 800;
  letter-spacing: 0.05rem;
  line-height: 2rem;
}

.body-top-content {
  font-size: clamp(15px, (15px + 2vw), 18px);
}

.body-top-content-2 {
  font-size: clamp(13px, (13px + 2vw), 16px);
}

.body-top-content-3 {
  font-size: clamp(12px, (12px + 2vw), 15px);
}

#button:hover {
  box-shadow: 0px 7px 25px 0px #ff7a0d;
}

#button {
  font-size: clamp(15px, (15px + 2vw), 18px);
  background-color: #ff7a0d;
  border-radius: 50px 0 50px 50px;
  font-weight: 800;
  border: solid #ff7a0d;
  padding: 0.75rem 3rem;
  letter-spacing: 0.05rem;
}

#home-button {
  font-size: clamp(10px, (10px + 2vw), 13px);
  font-weight: 800;
  border-radius: 50px;
  letter-spacing: 0.05rem;
}

.nav-link {
  font-size: clamp(10px, (10px + 2vw), 13px);
  font-weight: 700;
  color: #ffffff !important;
  margin-right: 2rem;
  letter-spacing: 0.05rem;
}

.title {
  font-size: clamp(13px, (13px + 2vw), 16px);
  font-weight: 600;
  margin-top: 1rem;
}

.btn-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: solid #9a9a9a 3px;
  margin-right: 0.5rem;
}

#copyright-font {
  font-size: clamp(9px, (9px + 2vw), 12px);
  font-weight: 500;
  color: #c0c0c0;
}

.faIcon {
  font-size: clamp(9px, (9px + 2vw), 12px);
  font-weight: 400;
  color: #9a9a9a;
}

.navbar-space {
  padding-top: calc(1vh + (50 - 1) * ((100vw - 1024px) / (3072 - 1024)));
}

.ptvh-2 {
  padding-top: 2vh;
}

ul {
  list-style-type: square;
  margin-left: 0;
  padding-left: 1.5vw;
}

ul,
li {
  font-size: clamp(15px, (15px + 2vw), 18px);
}

.mtbvh-4 {
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.plvw-5 {
  padding-left: 5vw;
}

#prvw-1 {
  padding-right: 2vw;
}

.academy {
  margin: 0 15rem 1rem 15rem;
}

.video-card {
  border: 1px solid #c0c0c0;
}

.tutorial-iframe {
  height: 25rem;
}

.tutorial-iframe-2 {
  height: 11rem;
}

.tutorial-videos {
  padding: 0 15rem;
}

.padding-tutorial {
  padding: 3rem 0;
}

@media screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  #tutorial-page {
    background-image: none,
      url("/assets/pageheader-mobile.svg#tutorial-landscape");
    background-size: 100% 100%;
    background-repeat: no-repeat, no-repeat;
  }

  .title,
  .description {
    text-align: center;
  }

  .tutorial-iframe {
    width: 100%;
    height: 17rem;
  }

  .tutorial-videos {
    padding: 0 1.5rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  body {
    overflow-x: hidden;
  }

  #home-page {
    background-image: none;
    padding-bottom: 0;
  }

  .card-content {
    font-size: clamp(10px, (10px + 2vw), 12px);
    min-height: 5rem;
  }

  #tutorial-page {
    background-image: none, url("/assets/pageheader-tablet.svg#tutorial");
    background-size: 100% 100%;
    background-repeat: no-repeat, no-repeat;
  }

  .navbar {
    padding-top: 1.5rem;
  }

  .title,
  .description {
    text-align: center;
  }

  .tutorial-iframe {
    width: 100%;
    height: 20rem;
  }

  .tutorial-videos {
    padding: 0 3rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #home-page {
    background-image: url(/assets/home-bg.png);
    background-size: contain;
    background-repeat: no-repeat, no-repeat;
    background-position: right 100px;
  }

  .card-content {
    font-size: clamp(10px, (10px + 2vw), 12px);
    min-height: 8rem;
  }

  #tutorial-page {
    background-image: none,
      url("/assets/pageheader-tablet.svg#tutorial-landscape");
    background-size: 100% 100%;
    background-repeat: no-repeat, no-repeat;
  }

  .navbar {
    padding-top: 1.5rem;
  }

  .title,
  .description {
    padding: 0 2rem;
    text-align: center;
  }

  .tutorial-iframe {
    width: 100%;
    height: 25rem;
  }

  .tutorial-videos {
    padding: 0 2rem;
  }
}

@media only screen and (min-width: 280px) and (max-width: 540px) {
  body {
    overflow-x: hidden;
  }

  .override-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .override-padding {
    padding: 0;
  }

  .card-content {
    font-size: clamp(10px, (10px + 2vw), 12px);
    min-height: 4rem;
  }

  #tutorial-page {
    background-image: none, url("/assets/pageheader-mobile.svg#tutorial");
    background-size: 100% 100%;
    background-repeat: no-repeat, no-repeat;
  }

  .tutorial-iframe {
    width: 100%;
    height: 13rem;
  }

  .tutorial-videos {
    padding: 0 1.5rem;
  }

  ul {
    list-style-type: square;
    margin-left: 0;
    padding-left: 5vw;
  }

  .navbar {
    padding: 1rem;
  }

  i {
    font-size: calc(14px + (19 - 14) * ((100vw - 280px) / (540 - 280)));
    line-height: calc(2.8vh + (15 - 2.8) * ((100vw - 280px) / (540 - 280)));
  }
}
